<template>
  <div class="electricity-index pa-10">



    <title-page icon="ico-carplug" class="mb-0">
      {{ $t("electricity-index.add") }}
    </title-page>

    <div class="mt-5">
      <headline>{{ $tc('electricity-index.electricity-index') }}</headline>
      <v-row>
        <v-col cols="12">
          <v-form ref="form" @submit.prevent="save">
            <v-row>
              <v-col cols="12">
                <form-field
                    :label="$t('name')"
                    v-model="form.name"
                    :rules="[
                        commonRules.required,
                    ]"
                    col-field="9"
                />
              </v-col>
            </v-row>

            <v-row class="mt-10">
              <v-col cols="12" class="text-center">
                <v-btn type="submit" color="primary">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-form>


          <v-divider class="my-10"></v-divider>

          <v-row>
            <v-col class="text-center">
              <v-btn small @click="deleteItem">
                {{ $t('delete') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

    </div>

  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import RulesMixin from "@mixins/RulesMixin";
import ElectricityIndexRepository from "@repository/ElectricityIndexRepository";

export default {
  components: {

  },
  data() {
    return {
      form: {
        name: null
      },
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    if(this.isEdit) {
      this.loadData()
    }

  },

  watch: {
    form: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    }
  },

  computed: {
    id() {
      return this.$route.params.id ?? null
    },

    isEdit() {
      return !!this.id
    }
  },

  methods: {


    cancel() {

    },


    loadData() {
      ElectricityIndexRepository.get(this.id).then(result => {
        this.form = result
        this.$nextTick(() => {
          this.cleanUnsavedChanges()
        })
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    save() {

      if(this.$refs.form.validate()) {
        this.showLoading(true)

        if(this.isEdit) {
          ElectricityIndexRepository.update(this.id, this.form).then(response => {
            this.showLoading(false)
            this.cleanUnsavedChanges()
            this.$dialog.notify.success(this.$t('update-success'))
            this.$router.push({name: 'electricity-index'})
          }).catch(err => {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
          })
        } else {
          ElectricityIndexRepository.create(this.form).then(response => {
            this.showLoading(false)
            this.cleanUnsavedChanges()
            this.$dialog.notify.success(this.$t('created-successfully'))
            this.$router.push({name: 'electricity-index'})
          }).catch(err => {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
          })
        }

      }

    },

    deleteItem() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          ElectricityIndexRepository.delete(this.id).then(result => {
            this.showLoading(false)
            this.$dialog.notify.success(this.$t('delete-success'))
            this.$router.replace({name: 'electricity-index'})
          }).catch(err => {
            this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
            this.showLoading(false)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.electricity-index-detail {

}
</style>