import Vue from 'vue'

class ElectricityTariffRepository {

  search(electricityIndexId, offset = 0, limit = 20, orderBy = 'validityStartDate', orderDirection = 'desc') {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/electricity-tariff/search',
        method: 'post',
        data: {
            electricityIndexId,
            offset,
            limit,
            orderBy,
            orderDirection,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  create(electricityIndexId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/electricity-tariff',
        method: 'post',
        data: {
          electricityIndexId,
          ...data,
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/electricity-tariff/' + id,
        method: 'put',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/electricity-tariff/' + id,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/electricity-tariff/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new ElectricityTariffRepository()